import { useState } from "react";
import "./App.css";
import {
  FaDiscord,
  FaTwitter,
  FaGithub,
  FaRobot,
  FaLanguage,
} from "react-icons/fa";
const Settings = () => {
  return (
    <div className="settings">
      <h2>Settings</h2>
      <ul className="settings-ul">
        <li>
          {" "}
          Seconds: <input type="number" className="second-input" />{" "}
        </li>
      </ul>
    </div>
  );
};

function App() {
  const [isActive, setIsActive] = useState(false);
  const [clicks, setClicks] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [text, setText] = useState("Click to start!");
  const [disableClick, setDisableClick] = useState(false);
  const [customSeconds, setCustomSeconds] = useState(null);

  var secs = 0;
  const handleClick = () => {
    if (disableClick) return;
    if (!isActive) {
      setSeconds(customSeconds ?? 5);
      secs = customSeconds ?? 5;
      setClicks(0);
      countdown();
      setIsActive(true);
    } else {
      setClicks(clicks + 1);
    }
  };

  const handleSecondChange = (e) => {
    setCustomSeconds(e.target.value);
  };

  function countdown() {
    const cdInterval = setInterval(() => {
      secs = secs - 0.01;
      secs = secs.toFixed(2);
      setSeconds(secs);
      if (secs < 0) {
        clearInterval(cdInterval);
        setDisableClick(true);
        setText("Finish!");
        setIsActive(false);
        setSeconds(0);
        setTimeout(() => {
          setDisableClick(false);
          setText("Click to start!");
        }, 800);
      }
    }, 10);
  }

  const RenderInfo = () => {
    return (
      <div className="stats">
        <div className="stat">
          <span className="gradient">
            Time: {seconds}
            <span className="dont-lower">s</span>{" "}
          </span>
        </div>
        <div className="stat">
          <span className="gradient">Clicks: {clicks}</span>
        </div>
        <div className="stat">
          <span className="gradient ">
            CPS:{" "}
            {clicks / ((customSeconds ?? 5) - seconds) === NaN
              ? 0
              : (clicks / ((customSeconds ?? 5) - seconds)).toFixed(2)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="wrapper">
        <h1>
          Welcome to <span className="gradient-text">SpicyJungle's</span> CPS
          measurement tool!
        </h1>

        <div className="clicker" onClick={handleClick}>
          <span className={isActive ? "active" : ""}>
            {" "}
            {isActive ? "c l i c k" : text}{" "}
          </span>
        </div>
        <RenderInfo />
        <div className="enter-number">
          <span className="gradient seconds-span">Seconds: </span>
          <input
            type="number"
            className="second-input"
            onChange={(e) => handleSecondChange(e)}
          />
        </div>
        <ul className="icon-links">
          <li>
            <a href="https://discord.spicyjungle.dev/">
              <FaDiscord />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/SpicyJungle">
              <FaTwitter />
            </a>
          </li>
          <li>
            <a href="https://github.com/SpicyJungle">
              <FaGithub />
            </a>
          </li>
          <li>
            <a href="https://top.gg/bot/817941359041314846">
              <FaRobot />
            </a>
          </li>
          <li>
            <a href="https://lingo.fish">
              <FaLanguage />
            </a>
          </li>
        </ul>
        <div className="results"></div>
      </div>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </>
  );
}

export default App;
